<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()
</script>

<template>
    <div>
        {{ store.translate("cancellation") }}:<br />
        <p>
            {{ store.translate("cancel_text") }}
        </p>
        <span v-if="store.company?.company_number">
            <a v-bind:href="store.cancelmailtolink()" target="_blank">
                <button class="btn btn-primary btn-user btn-block">
                    {{ store.translate("cancellation") }}
                </button>
            </a>
        </span>
    </div>
</template>

<style scoped></style>
