<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()
</script>

<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

        <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/dashboard">
            <div class="sidebar-brand-icon">
                <img src="../assets/logo.jpg" class="logo">
            </div>
            <div class="sidebar-brand-text mx-3">
                Proxmox Backup
            </div>
        </a>

        <hr class="sidebar-divider my-0">

        <li class="nav-item">
            <a class="nav-link" href="/dashboard">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>{{ store.translate('sidebar_dashboard') }}</span>
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link" href="/company">
                <i class="fas fa-fw fa-cog"></i>
                <span>{{ store.translate('sidebar_company') }}</span>
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link" href="/tutorial">
                <i class="fas fa-fw fa-list"></i>
                <span>{{ store.translate('sidebar_tutorial') }}</span>
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link" href="#" @click="() => { store.logout() }">
                <i class="fas fa-fw fa-user"></i>
                <span>{{ store.translate('sidebar_logout') }}</span>
            </a>
        </li>

        <hr class="sidebar-divider">

        <li class="nav-item">
            <a class="nav-link" href="mailto:support@inett.de">
                <i class="fas fa-fw fa-receipt"></i>
                <span>{{ store.translate('support') }}</span>
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link" target="_blank" href="https://status.inett.de">
                <i class="fas fa-fw fa-receipt"></i>
                <span>{{ store.translate('statuspage') }}</span>
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link" href="https://www.inett.de/datenschutz">
                <i class="fas fa-fw fa-receipt"></i>
                <span>{{ store.translate('privacy_link') }}</span>
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link" href="https://www.inett.de/impressum">
                <i class="fas fa-fw fa-receipt"></i>
                <span>{{ store.translate('imprint') }}</span>
            </a>
        </li>
    </ul>
</template>

<style scoped></style>
