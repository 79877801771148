<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()

let params = window.location.search
params = params.replace("?", "")
let param_array = params.split("&")

param_array.forEach(param => {
    let values = param.split("=")
    let key = values[0]
    let value = values[1]

    if (key == "email") {
        store.email = value
    }
    if (key == "token") {
        store.token = value
    }
})

function changePassword() {
    store.changePassword()
}
</script>

<template>
    <div class="user">
        <div class="form-group">
            <input type="password" class="form-control form-control-user" v-model="store.newPassword_1"
                placeholder="Neues Passwort">
        </div>
        <div class="form-group">
            <input type="password" class="form-control form-control-user" v-model="store.newPassword_2"
                placeholder="Neues Passwort">
        </div>
        <p>
            {{ store.errorMessage }}
        </p>
     </div>
    <button class="btn btn-primary btn-user btn-block" :disabled="store.loading" @click="changePassword"
        @submit.prevent>
        {{ store.translate('change_password_button') }}
    </button>
    <br /><br />
    <p>
        {{ store.message }}
    </p>
</template>

<style scoped>
h1 {
    font-weight: 500;
    font-size: 2.6rem;
    position: relative;
    top: -10px;
}

h3 {
    font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
    text-align: center;
}

@media (min-width: 1024px) {

    .greetings h1,
    .greetings h3 {
        text-align: left;
    }
}
</style>
