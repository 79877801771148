<script setup>
import { useMainStore } from '@/stores/main'
import Slider from "vue3-slider"
const store = useMainStore()
</script>

<template>
    <div class="product-list col-xl-6 col-md-6 mb-12">
        <div class="card border-left-secondary shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col">
                        <div class="text-xs font-weight-bold text-uppercase mb-1">
                            Dynamisch
                        </div>
                        <div class="h5 font-weight-bold text-gray-800">
                            {{ store.translate("static_price_gigabyte") }}
                        </div>
                        <div class="text-s mb-1">
                            <ul class="features">
                                <li>
                                    {{ store.translate("text_dynamic_product") }}
                                </li>
                                <li>
                                    {{ store.translate("text_legal_product_infos_1") }}
                                </li>
                                <li>
                                    {{ store.translate("text_legal_product_infos_2") }}
                                </li>
                                <li>
                                    {{ store.translate("text_legal_product_infos_3") }}
                                </li>
                                <li>
                                    {{ store.translate("text_legal_product_infos_4") }}
                                </li>
                                <li>
                                    <a
                                        target="_blank" 
                                        href="https://www.inett.de/assets/files/Leistungsbeschreibung-inett-Self-Managed-Proxmox-Backup-Server-Stand-2024-10-11.pdf">
                                        {{ store.translate("service_description") }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style="margin-right: 20px;">
                        <div v-if="store.possibleToBuy()">
                            <a href="#" class="btn btn-primary btn-icon-split"
                                @click="() => { store.buyProduct(0) }">
                                <span class="icon text-white-50">
                                    <i class="fas fa-check"></i>
                                </span>
                                <span class="text">
                                    {{ store.translate("buy") }}
                                </span>
                            </a>
                        </div>
                        <div v-else>
                            <a href="#" class="btn btn-primary btn-icon-split disabled">
                                <span class="icon text-white-50">
                                    <i class="fas fa-check"></i>
                                </span>
                                <span class="text">
                                    {{ store.translate("buy") }}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <p>
                    {{ store.errorMessage }}
                </p>
            </div>
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col">
                        <div class="text-xs font-weight-bold text-uppercase mb-1">
                            Statisch
                        </div>
                        <div class="h5 font-weight-bold text-gray-800">
                            {{ store.getVolumeInGB() }} Gigabyte für {{ store.calcPrice() }} Euro / Monat
                        </div>
                        <div class="slider-wrapper">
                            <Slider v-model="store.volume" :min=1 :max="parseInt(store.maxVolume)" :step=1
                                :alwaysShowHandle=true track-color="#CCC" />
                        </div>
                        <div class="text-s mb-1">
                            <ul class="features">
                                <li>
                                    {{ store.translate("text_static_product") }}
                                </li>
                                <li>
                                    {{ store.translate("text_legal_product_infos_1") }}
                                </li>
                                <li>
                                    {{ store.translate("text_legal_product_infos_2") }}
                                </li>
                                <li>
                                    {{ store.translate("text_legal_product_infos_3") }}
                                </li>
                                <li>
                                    {{ store.translate("text_legal_product_infos_4") }}
                                </li>
                                <li>
                                    {{ store.translate("text_legal_product_infos_5") }}
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        href="https://www.inett.de/assets/files/Leistungsbeschreibung-inett-Self-Managed-Proxmox-Backup-Server-Stand-2024-10-11.pdf">
                                        {{ store.translate("service_description") }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style="margin-right: 20px;">
                        <div v-if="store.possibleToBuy()">
                            <a href="#" class="btn btn-primary btn-icon-split" @click="() => { store.buyProduct(store.volume) }
                                ">
                                <span class="icon text-white-50">
                                    <i class="fas fa-check"></i>
                                </span>
                                <span class="text">Kaufen</span>
                            </a>
                        </div>
                        <div v-else>
                            <a href="#" class="btn btn-primary btn-icon-split disabled">
                                <span class="icon text-white-50">
                                    <i class="fas fa-check"></i>
                                </span>
                                <span class="text">Kaufen</span>
                            </a>
                        </div>
                    </div>
                </div>
                <p>
                    {{ store.errorMessage }}
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
