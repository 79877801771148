<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()

import ForgotPasswordForm from '../components/ForgotPasswordForm.vue'
import LinkBar from '../components/LinkBar.vue'
import ImageCol from '@/components/ImageCol.vue';
document.querySelector("body").classList.add('bg-gradient-primary')
</script>

<template>
    <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row min-height">
                        <ImageCol></ImageCol>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-4">
                                        {{ store.translate("forgot_pw_button") }}?
                                    </h1>
                                </div>
                                <p>
                                    {{ store.translate("forgot_pw_text_1") }}
                                </p>
                                <p>
                                    {{ store.translate("forgot_pw_text_2") }}
                                </p>
                                <ForgotPasswordForm />
                                <hr>
                                <LinkBar></LinkBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
