<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()
</script>

<template>
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">
            <!-- Nav Item - User Information -->
            <li class="nav-item" style="margin: 25px 10px 0px 0px;">
                <span class="text-gray-600">{{ store.email }}</span>
            </li>
            <li class="nav-item no-arrow">
                
                <div class="nav-link">
                    <img class="img-profile rounded-circle" src="../assets/profile.png">
                </div>
            </li>
        </ul>
    </nav>
</template>

<style scoped></style>