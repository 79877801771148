<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()
</script>

<template>
    <span class="text-center">
        <a class="small" href="/login">
            {{ store.translate("login") }}
        </a>
    </span>
    &nbsp;
    <span class="text-center">
        <a class="small" href="/register">
            {{ store.translate("register_account") }}
        </a>
    </span>
    &nbsp;
    <span class="text-center">
        <a class="small" href="/reset">
            {{ store.translate("forgot_pw_button") }}
        </a>
    </span>
    &nbsp;
    <span class="text-center">
        <a class="small" href="https://www.inett.de/datenschutz">
            {{ store.translate("privacy_link") }}
        </a>
    </span>
    &nbsp;
    <span class="text-center">
        <a class="small" href="https://www.inett.de/impressum">
            {{ store.translate("imprint") }}
        </a>
    </span>
    &nbsp;
    <span class="small font-grey">
        {{ store.translate("customer_information_vat") }}
    </span>
</template>

<style scoped></style>
