<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()

store.getUser()
</script>

<template>
    <div>
        E-Mail: {{ store.user?.email }}<br/>
        <div v-if="store.user?.company_number">
            Kundennummer: {{ store.user?.company_number }}
            <hr>
        </div>
    </div>
</template>

<style scoped></style>
