<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()
</script>

<template>
    <div>
        <h2>{{ store.translate("headline_setup_storage") }}</h2>

        <p>
            {{ store.translate("storage-text-0") }}
        </p>
        <p>
            {{ store.translate("storage-text-1") }} {{ store.translate("text_av_contract") }}
        </p>
        <p>
            {{ store.translate("storage-text-2" ) }} <a href="/dashboard">{{ store.translate("dashboard-link") }}</a>.
        </p>
        <img v-if="store.language == 'en'" src="../assets/images/storage-light-1-en.png" class="tut-images"></img>
        <img v-if="store.language == 'de'" src="../assets/images/storage-light-1-de.png" class="tut-images"></img>

        <p>
            {{ store.translate("storage-text-3" ) }}
        </p>
        <img v-if="store.language == 'en'" src="../assets/images/storage-light-2-en.png" class="tut-images"></img>
        <img v-if="store.language == 'de'" src="../assets/images/storage-light-2-de.png" class="tut-images"></img>

        <p>
            {{ store.translate("storage-text-4" ) }}
        </p>
        <img v-if="store.language == 'en'" src="../assets/images/storage-light-3-en.png" class="tut-images"></img>
        <img v-if="store.language == 'de'" src="../assets/images/storage-light-3-de.png" class="tut-images"></img>
    </div>
</template>

<style scoped></style>
