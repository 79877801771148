import { defineStore } from 'pinia'
import { t } from './language'
import { useRoute } from 'vue-router'

const apiURL = import.meta.env.VITE_ROOT_API
const maxVolume = import.meta.env.VITE_MAX_VOLUME
const ssp_mail = import.meta.env.VITE_SSP_MAIL

export const useMainStore = defineStore('store', {
    state: () => ({
        email: "",
        firstname: "",
        lastname: "",
        name: "",
        volume: 1,
        paidVolume: 1,
        maxVolume: maxVolume,
        ssp_mail: ssp_mail,
        user: {},
        company: {},
        status: {},
        pending_name: "",
        pending_street: "",
        pending_postcode: "",
        pending_city: "",
        pending_state: "",
        pending_country: "DE",
        pending_ustid: "",
        phone: "",
        space_used: "",
        space_available: "",
        space_total: "",
        newPassword_1: "",
        newPassword_2: "",
        datastore: "",
        repository: "",
        proxmox_hostname: "",
        proxmox_port: "",
        proxmox_username: "",
        proxmox_password: "",
        show_proxmox_password: false,
        proxmox_costs: "",
        counts: {},
        language: "de",
        token: "",
        message: "",
        form_message: "",
        loggedin: false,
        errorMessage: "",
        loginMessage: "",
        loading: false,
        loadingTimer: {}
    }),
    getters: {

    },
    actions: {
        async registerCompany() {
            this.setLoading(true)
            let response = await fetch(`${apiURL}/company`, {
                method: "post",
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: this.email,
                    password: this.password,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    phone: this.phone,
                    pending_name: this.pending_name,
                    pending_street: this.pending_street,
                    pending_postcode: this.pending_postcode,
                    pending_city: this.pending_city,
                    pending_state: this.pending_state,
                    pending_country: this.pending_country,
                    pending_ustid: this.pending_ustid
                })
            })
            const register = await response.json()
            if (register.status == "done") {
                window.location.href = "/waiting"
            } else {
                this.showErrorMessage(register)
            }
            this.setLoading(false)
        },
        async sendLogin() {
            this.setLoading(true)
            this.loginMessage = ""
            let response = await fetch(`${apiURL}/login`, {
                method: "post",
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: this.email,
                    password: this.password
                })
            })
            const login = await response.json()
            if (response.status == 401 || login.is_active == false) {
                // show message that this user needs to be activated
                this.loginMessage = this.translate("account_not_active")
            }
            if (login.is_active == true) {
                this.loggedin = true
                window.location = "/dashboard"
            }
            this.password = ""
            this.loginMessage = this.translate("error_username_or_password")
            this.setLoading(false)
        },
        async resetPassword() {
            this.message = ""
            this.setLoading(true)
            let response = await fetch(`${apiURL}/forgotpassword`, {
                method: "post",
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: this.email
                })
            })
            const reset = await response.json()
            if (reset.status == "done") {
                this.loggedin = false
                this.message = this.translate("password_request_send")
            } else {
                this.showErrorMessage(reset)
            }
            this.setLoading(false)
        },
        async changePassword() {
            if (this.newPassword_1 != this.newPassword_2) {
                this.errorMessage = this.translate("passwords_dont_match")
                return;
            }
            this.setLoading(true)
            let response = await fetch(`${apiURL}/changepassword`, {
                method: "post",
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "email": this.email,
                    "password": this.newPassword_1,
                    "token": this.token
                })
            })
            this.setLoading(false)
            const reset = await response.json()
            if (reset.status == "done") {
                this.message = this.translate("password_changed")
                window.location.href = "/login"
            } else {
                this.showErrorMessage(reset)
            }
        },
        async getUser() {
            let response = await fetch(`${apiURL}/me`, {
                credentials: "include"
            })
            if (response.status != 200) {
                this.loggedin = false
                return;
            }
            const json_stuff = await response.json()
            this.user = json_stuff
            this.email = json_stuff.email
        },
        async getCompany() {
            let response = await fetch(`${apiURL}/company`, {
                credentials: "include"
            })
            const json_stuff = await response.json()
            this.company = json_stuff
        },
        async buyProduct(volume) {
            this.message = ""
            this.setLoading(true)
            let response = await fetch(`${apiURL}/product/volume/${volume}`, {
                credentials: "include"
            })
            this.setLoading(false)
            const json_response = await response.json()
            if (response.status == 200) {
                window.location.href = "/dashboard"
            } else {
                this.showErrorMessage(json_response)
            }
        },
        async sendContactMessage() {
            this.message = ""
            this.setLoading(true)
            let response = await fetch(`${apiURL}/contact`, {
                method: "post",
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: this.pending_name,
                    email: this.email,
                    message: this.form_message
                })
            })
            this.setLoading(false)
            const json_response = await response.json()
            if (response.status == 200) {
                this.message = this.t("contact_message_send")
            } else {
                this.showErrorMessage(json_response)
            }
        },
        async getStatus() {
            let response = await fetch(`${apiURL}/status`, {
                credentials: "include"
            })
            if (response.status != 200) {
                this.loggedin = false
                return;
            }
            const status = await response.json()
            this.status = status

            this.space_available = status.space_available_text
            this.space_used = status.space_used_text
            this.space_total = status.space_total_text
            this.datastore = status.datastore
            this.repository = status.repository
            this.proxmox_username = status.username
            this.proxmox_password = status.password
            this.proxmox_hostname = status.hostname
            this.proxmox_port = status.port
            this.proxmox_costs = status.price
            this.counts = status.counts
            this.paidVolume = status.volume
            this.dynamicVolume = status.dynamic_volume
        },
        getSpaceUsage(css) {
            if (!this.status) {
                return 0;
            }
            let percent = 100 / this.status?.space_total * this.status?.space_used
            if (css) {
                return `width: ${Math.round(percent)}%`;
            } else {
                return Math.round(percent);
            }
        },
        getSpaceBarColor() {
            let percent = this.getSpaceUsage()
            if (percent > 80) {
                return "progress-bar bg-warning";
            } else {
                return "progress-bar bg-success";
            }
        },
        showErrorMessage(response) {
            if (response.reason) {
                this.errorMessage = response.reason
                setTimeout(() => {
                    this.errorMessage = ""
                }, 30 * 1000)
            }
        },
        is_loggedin() {
            if (!this.loggedin) {
                window.location.href = "/login"
            }
        },
        calcPrice() {
            return this.getVolume() * 20;
        },
        getVolume() {
            if (this.volume) {
                return this.volume.toString();
            }
            return "1";
        },
        getVolumeInGB() {
            if (this.volume) {
                return (this.volume * 1000).toLocaleString();
            }
            return "1.000";
        },
        translate(keyword) {
            return t(this.language, keyword);
        },
        routerMessage() {
            const route = useRoute()
            if (route.params.message == "registered") {
                return "Bitte bestätigen Sie Ihren Account per E-Mail.";
            }
            if (route.params.message == "approval") {
                return "Bitte warten Sie bis Ihr Account innerhalb 3 Werktagen freigeschaltet wurde.";
            }
            return ""
        },
        setLoading(bool) {
            clearTimeout(this.loadingTimer)
            if (bool) {
                this.loading = true
                this.loadingTimer = setTimeout(() => {
                    this.loading = false
                }, 120 * 1000)
            } else {
                this.loading = false
            }
        },
        copyToClipboard(copy_text) {
            navigator.clipboard.writeText(copy_text)
        },
        togglePasswordVisibility() {
            if (this.show_proxmox_password) {
                this.show_proxmox_password = false
            } else {
                this.show_proxmox_password = true
            }
        },
        getProxmoxPasswrd() {
            if (this.show_proxmox_password) {
                return this.proxmox_password;
            } else {
                return "*************************************************************";
            }
        },
        possibleToBuy() {
            if (this.loading) {
                return false;
            }
            if (this.proxmox_username) {
                return false;
            }
            return true;
        },
        cancelmailtolink() {
            return `mailto:${this.ssp_mail}&subject=${this.translate('cancel_subject_mail')}. ${this.translate('company_number')}: ${this.company?.company_number}`;
        },
        async logout() {
            let response = await fetch(`${apiURL}/logout`, {
                credentials: "include"
            })
            const logout = await response.json()
            if (logout.status == "done" || response.status == 401) {
                this.loggedin = false
                window.location.href = "/login"
            }
        }
    },
    persist: {
        storage: sessionStorage,
        paths: ["loggedin"]
    }
})