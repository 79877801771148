<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()
</script>

<template>
    <div>
        <h2>{{ store.translate("headline_setup_backup_job") }}</h2>

        <p>
            {{ store.translate("backupjob-text-1" ) }}
        </p>
        <img v-if="store.language == 'en'" src="../assets/images/backup-light-1-en.png" class="tut-images"></img>
        <img v-if="store.language == 'de'" src="../assets/images/backup-light-1-de.png" class="tut-images"></img>

        <p> 
            {{ store.translate("backupjob-text-2" ) }}
        </p>
        <img v-if="store.language == 'en'" src="../assets/images/backup-light-2-en.png" class="tut-images"></img>
        <img v-if="store.language == 'de'" src="../assets/images/backup-light-2-de.png" class="tut-images"></img>

        <p>
            {{ store.translate("backupjob-text-3" ) }}
        </p>
        <img v-if="store.language == 'en'" src="../assets/images/backup-light-3-en.png" class="tut-images"></img>
        <img v-if="store.language == 'de'" src="../assets/images/backup-light-3-de.png" class="tut-images"></img>

        <p>
            {{ store.translate("backupjob-text-4" ) }}
        </p>
        <img v-if="store.language == 'en'" src="../assets/images/backup-light-4-en.png" class="tut-images"></img>
        <img v-if="store.language == 'de'" src="../assets/images/backup-light-4-de.png" class="tut-images"></img>

        <p>
            {{ store.translate("backupjob-text-5" ) }}
        </p>
</div>
</template>

<style scoped></style>
