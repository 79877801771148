<script setup>

</script>

<template>
    <div class="col-lg-6 d-none d-lg-block background-cover">
        <a href="/">
            <img src="../assets/logo.jpg" class="logo-cover">
        </a>
    </div>
</template>

<style scoped></style>
